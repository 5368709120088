import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { EquipmentGroupDisplay, FederalSupplyClassDisplay, FederalSupplyClassFCSRef, InventoryClassDisplay, ItemInstanceInventoryTypeDisplay } from '~/nasa_ui/types';
import { addNodeIdToResponseObj } from '~/nasa_ui/vue_plugins/axios';
import { periodResponseDisplay } from '../../dates/periodResponse';
import { CosmicIcons } from '../../ui/CosmicIcons';
import { addAsBuiltNumberDisplay, addAsDesignNumberDisplay, addBuildingStockBinDisplay, addComputedLastInspectionDate, addContactDisplays, addCrossContextLocationDisplay, addDateDisplays, addDescriptionDisplay, addDrawingNumberDisplay, addInitializationStatusDisplay, addInventoryPlusMethodDisplay, addInventoryQuantityDisplay, addInventoryStatusDisplay, addIsToolIcon, addItemNumberDisplay, addLastServiceDate, addLocationDisplay, addLotNumberDisplay, addMannedPressurizedTime, addMannedPressurizedTimeSinceLastService, addOverrideSoftwareVersionDisplay, addProjectCodeDisplay, addProjectCodesDisplay, addQuantitiesDisplay, addSerialNumberDisplay, addServicePressurizedTime, addSideDisplay, addSinceLastInspection, addSizeDisplay, addSizesDisplay, addSoftwareVersionDisplay, addSubTypeDisplay, addUnitCost } from '../displayMappers';
import { booleanIconDisplay } from '../displays';
import { isNullOrUndefined } from '../isNullOrUndefined';
export const transformInventories = (items) => {
    return items
        .map(addAsBuiltNumberDisplay)
        .map(addBuildingStockBinDisplay)
        .map(addCrossContextLocationDisplay)
        .map(addDescriptionDisplay)
        .map(addInventoryPlusMethodDisplay)
        .map(addInventoryStatusDisplay)
        .map(addIsToolIcon)
        .map(addItemNumberDisplay)
        .map(addLocationDisplay)
        .map(addLotNumberDisplay)
        .map(addOverrideSoftwareVersionDisplay)
        .map(addProjectCodeDisplay)
        .map(addSerialNumberDisplay)
        .map(addSideDisplay)
        .map(addSizeDisplay)
        .map(addSoftwareVersionDisplay)
        .map(addSubTypeDisplay(ItemInstanceInventoryTypeDisplay))
        .map(addUnitCost)
        .map((item) => {
        let icon = '';
        if (item.isInstalled) {
            icon = `
          <i
            class="fa-2x ${CosmicIcons['true']} success--text"
            title="This item is INSTALLED on another item"
          ></i>`;
        }
        else {
            icon = `<i class="fa-2x ${CosmicIcons['false']} secondary--text" title="Not installed"></i>`;
        }
        item._isInstalled = icon;
        return item;
    });
};
export const transformInventoryResponses = (items) => {
    return transformInventories(items);
};
const isUniformInventoryClass = (input) => {
    if (Array.isArray(input)) {
        if (input.length === 0) {
            return true;
        }
        const firstClass = input[0];
        return input.every((item) => item === firstClass);
    }
    else {
        // If the input is a single string
        return true;
    }
};
export const transformItemInstances = (items) => {
    return items
        .map(addAsBuiltNumberDisplay)
        .map(addAsDesignNumberDisplay)
        .map(addComputedLastInspectionDate)
        .map(addDateDisplays)
        .map(addDescriptionDisplay)
        .map(addInitializationStatusDisplay)
        .map(addInventoryQuantityDisplay)
        .map(addIsToolIcon)
        .map(addItemNumberDisplay)
        .map(addLastServiceDate)
        .map(addLotNumberDisplay)
        .map(addMannedPressurizedTime)
        .map(addMannedPressurizedTimeSinceLastService)
        .map(addNodeIdToResponseObj)
        .map(addProjectCodesDisplay)
        .map(addSerialNumberDisplay)
        .map(addServicePressurizedTime)
        .map(addSideDisplay)
        .map(addSinceLastInspection)
        .map(addSizeDisplay)
        .map((item) => {
        const _item = { ...item };
        // CEI
        _item._ceiNumber = _item.itemMaster?.contractEndingItemNumber ?? DEFAULT_DASH;
        // subType
        if (!_item.subType) {
            _item._subType = 'Multiple';
        }
        // if all InventoryClass in the [] are the same we say the subType is "that" type even though its stored on the Inventory
        // if all Inventory for this Instance arent the same we say its "Multiple"
        else if (_item.subType && isUniformInventoryClass(_item.subType) && Array.isArray(_item.subType)) {
            _item._subType = InventoryClassDisplay.get(_item.subType[0]);
        }
        else if (_item.subType && isUniformInventoryClass(_item.subType)) {
            _item._subType = InventoryClassDisplay.get(_item.subType);
        }
        else {
            _item._subType = `Multiple`;
        }
        _item._overrideMeanTimeToRepair = periodResponseDisplay(_item.overrideMeanTimeToRepair);
        _item._overrideSoftwareVersion = _item.overrideSoftwareVersion || DEFAULT_DASH;
        return _item;
    });
};
export const transformItemInstanceResponses = (items) => {
    return transformItemInstances(items);
};
export const transformItemInstanceResponseWithRefs = (items) => {
    return transformItemInstances(items);
};
export const transformItemMasters = (items) => {
    return items
        .map(addAsBuiltNumberDisplay)
        .map(addDateDisplays)
        .map(addDescriptionDisplay)
        .map(addDrawingNumberDisplay)
        .map(addInitializationStatusDisplay)
        .map(addItemNumberDisplay)
        .map(addQuantitiesDisplay)
        .map(addSideDisplay)
        .map(addSizesDisplay)
        .map(addIsToolIcon)
        .map(addSubTypeDisplay(EquipmentGroupDisplay))
        .map((item) => {
        const _item = { ...item };
        // Approved AD
        _item._approvalStatusIcon = !isNullOrUndefined(_item.isApprovedAsDesign)
            ? booleanIconDisplay(_item.isApprovedAsDesign)
            : DEFAULT_DASH;
        // FederalSupplyClass
        _item._federalSupplyClass = _item.federalSupplyClass
            ? FederalSupplyClassDisplay.get(_item.federalSupplyClass)
            : DEFAULT_DASH;
        _item._federalSupplyClassNumber = _item.federalSupplyClass
            ? FederalSupplyClassFCSRef.get(_item.federalSupplyClass)
            : DEFAULT_DASH;
        _item._hazardousMaterialText = _item.hazardousMaterialText || DEFAULT_DASH;
        _item._softwareVersion = _item.softwareVersion || DEFAULT_DASH;
        return _item;
    });
};
export const transformItemMasterResponses = (items) => {
    return transformItemMasters(items);
};
export const transformItemMasterResponseWithRefs = (items) => {
    return transformItemMasters(items);
};
export const transformItemDrawings = (items) => {
    return items
        .map(addContactDisplays)
        .map(addDateDisplays)
        .map(addInitializationStatusDisplay)
        .map(addDrawingNumberDisplay)
        .map(addItemNumberDisplay)
        .map(addDescriptionDisplay)
        .map(addQuantitiesDisplay)
        .map((item) => {
        // _eeeIdentifier
        const icon = booleanIconDisplay(item.eeeIdentifier);
        item._eeeIdentifier = icon;
        return item;
    })
        .map((item) => {
        const _item = { ...item };
        // _aliases
        if (_item.aliases && _item.aliases.length) {
            const aliases = _item.aliases.map((alias) => alias && alias.trim && `<li class="mt-1">${alias.trim()}</li>`);
            aliases.unshift('<ul>');
            aliases.push('</ul>');
            _item._aliases = aliases.join('');
        }
        else {
            _item._aliases = DEFAULT_DASH;
        }
        _item._meanTimeBetweenFailure = periodResponseDisplay(_item.meanTimeBetweenFailure);
        _item._meanTimeToRepair = periodResponseDisplay(_item.meanTimeToRepair);
        return _item;
    });
};
export const transformItemDrawingResponses = (items) => {
    return transformItemDrawings(items);
};
//#endregion
