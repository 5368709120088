import { CalibrationType, GroundSupportEquipmentType, HardwareListType, InventoryStatus, ItemInstanceInventoryType, ItemMasterCategoryCode, LimitedLifeType, OrganizationType } from '~/db_types';
export const HardwareOrganizationTypeDisplay = new Map([
    [OrganizationType.GOVERNMENT, 'Government'],
    [OrganizationType.MANUFACTURER, 'Manufacturer'],
    [OrganizationType.PROCESSOR, 'Processor'],
    [OrganizationType.SUPPLIER, 'Supplier'],
    [OrganizationType.VENDOR, 'Vendor'],
    [OrganizationType.CONTRACTOR, 'Contractor']
]);
export const ItemMasterCategoryCodeDisplay = new Map([
    [ItemMasterCategoryCode.AB, 'AB: VBAs KERNCO'],
    [ItemMasterCategoryCode.BE, 'BE: BUSINESS EQUIPMENT'],
    [ItemMasterCategoryCode.CA, 'CA: ADHESIVES/CHEMICALS/TAPES'],
    [ItemMasterCategoryCode.CC, 'CC: CREW CLOTHING'],
    [ItemMasterCategoryCode.CD, 'CD: CEE-DAVID CLARK'],
    [ItemMasterCategoryCode.CE, 'CE: COMPUTER HARDWARE/SOFTWARE'],
    [ItemMasterCategoryCode.CH, 'CH: CEE HARDWARE'],
    [ItemMasterCategoryCode.CP, 'CP: CREW PREFERENCE'],
    [ItemMasterCategoryCode.CR, 'CR: CAMERA SERVICE/REPAIR'],
    [ItemMasterCategoryCode.CS, 'CS: CEE SERVICE/REPAIR'],
    [ItemMasterCategoryCode.DA, 'DA: ADMINISTRATIVE CATEGORY'],
    [ItemMasterCategoryCode.DC, 'DC: CONTAINERS AND LABELS'],
    [ItemMasterCategoryCode.DD, 'DD: ADMINISTRATIVE CATEGORY'],
    [ItemMasterCategoryCode.DS, 'DS: COMMUNICATION'],
    [ItemMasterCategoryCode.DT, 'DT: ADMINISTRATIVE CATEGORY'],
    [ItemMasterCategoryCode.EE, 'EE: ELECTRICAL/ELECTRONIC ITEM_INSTANCES'],
    [ItemMasterCategoryCode.EH, 'EH: EMU HARDWARE'],
    [ItemMasterCategoryCode.EL, 'EL: ELECTROCHEM'],
    [ItemMasterCategoryCode.ES, 'ES: EMU SERVICE (SUB)'],
    [ItemMasterCategoryCode.FC, 'FC: FACILITIES MATERIAL AND EQUIPMENT'],
    [ItemMasterCategoryCode.FE, 'FE: FOOD EQUIPMENT'],
    [ItemMasterCategoryCode.FF, 'FF: FLIGHT FOOD'],
    [ItemMasterCategoryCode.FL, 'FL: FILM'],
    [ItemMasterCategoryCode.FS, 'FS: FASTENERS/SEALS/BEARINGS/PINS/CLAMPS'],
    [ItemMasterCategoryCode.GS, 'GS: COMPRESSED GASES, RECEIVABLES'],
    [ItemMasterCategoryCode.HF, 'HF: HOSE/TUBE/QD FITTINGS'],
    [ItemMasterCategoryCode.JH, 'JH: ADMINISTRATIVE CATEGORY'],
    [ItemMasterCategoryCode.JP, 'JP: ADMINISTRATIVE CATEGORY'],
    [ItemMasterCategoryCode.JS, 'JS: JANITORIAL SUPPLIES'],
    [ItemMasterCategoryCode.LS, 'LS: LAB/SAFETY/SHOP STOCK SUPPLIES'],
    [ItemMasterCategoryCode.LT, 'LT: TEST/LAB/COMMUNICATION EQUIPMENT'],
    [ItemMasterCategoryCode.MS, 'MS: ADMINISTRATIVE CATEGORY'],
    [ItemMasterCategoryCode.NF, 'NF: NON-FCE'],
    [ItemMasterCategoryCode.NI, 'NI: NEEDS INPUT'],
    [ItemMasterCategoryCode.NL, 'NL: TO CLOSE OUT NANCY LEVIT POS'],
    [ItemMasterCategoryCode.OC, 'OC: OFFICE/COMPUTER/PRINTING SUPPLIES'],
    [ItemMasterCategoryCode.PR, 'PR: FCE PRINTING/BUSINESS FORMS'],
    [ItemMasterCategoryCode.PS, 'PS: CAMERA EQUIPMENT'],
    [ItemMasterCategoryCode.PT, 'PT: PAN TILT/CAMERA- LOCKHEED MARTIN'],
    [ItemMasterCategoryCode.RF, 'RF: RAW FOOD'],
    [ItemMasterCategoryCode.RM, 'RM: RAW MATERIALS'],
    [ItemMasterCategoryCode.SC, 'SC: COMPUTER SERVICE'],
    [ItemMasterCategoryCode.SD, 'SD: COMMUNICATION SERVICE/REPAIR'],
    [ItemMasterCategoryCode.SE, 'SE: EMU SERVICE/REPAIR'],
    [ItemMasterCategoryCode.SF, 'SF: FOOD SERVICE'],
    [ItemMasterCategoryCode.SG, 'SG: COMPRESSED GASES, SERVICES'],
    [ItemMasterCategoryCode.SL, 'SL: FACILITIES SERVICE/LABOR/MAINT/BLANKET ORDERS'],
    [ItemMasterCategoryCode.SP, 'SP: SERVICE, CREW PREFERENCE/CLOTHING,ETC.'],
    [ItemMasterCategoryCode.SR, 'SR: SERVICES/REPAIRS/VEHICLES'],
    [ItemMasterCategoryCode.SS, 'SS: SECURITY'],
    [ItemMasterCategoryCode.ST, 'ST: SERVICES, TEMPORARY/TRAVEL'],
    [ItemMasterCategoryCode.SV, 'SV: ADMINISTRATIVE CATEGORY'],
    [ItemMasterCategoryCode.SW, 'SW: ADMINISTRATIVE CATEGORY'],
    [ItemMasterCategoryCode.TA, 'TA: HAND/POWER TOOLS AND ACCESSORIES'],
    [ItemMasterCategoryCode.TB, 'TB: ADMINISTRATIVE CATEGORY'],
    [ItemMasterCategoryCode.TR, 'TR: UNASSIGNED'],
    [ItemMasterCategoryCode.TS, 'TS: TOOL SUBASSEMBLIES AND ACCESSORIES'],
    [ItemMasterCategoryCode.TX, 'TX: TEXTILES'],
    [ItemMasterCategoryCode.UU, 'UU: ADMINISTRATIVE CATEGORY'],
    [ItemMasterCategoryCode.WH, 'WH: ADMINSTRATIVE CODE'],
    [ItemMasterCategoryCode.WW, 'WW: ADMINISTRATIVE CATEGORY'],
    [ItemMasterCategoryCode.XE, 'XE: XEMU HARDWARE'],
    [ItemMasterCategoryCode.ZA, 'ZA: ADMINISTRATIVE CATEGORY'],
    [ItemMasterCategoryCode.ZB, 'ZB: ADMINISTRATIVE CATEGORY'],
    [ItemMasterCategoryCode.ZU, 'ZU: II'],
    [ItemMasterCategoryCode.ZZ, 'ZZ: ADMINISTRATIVE CATEGORY']
]);
export const AssetUsageCodeDisplay = new Map([
    ['_10ELEC', '10ELEC: 0-10.0 VOLT, ELECTRICAL'],
    ['_120ELEC', '120ELEC: 28.1-120 VOLT, ELECTRICAL'],
    ['_150AIR', '150AIR: 15.1-150 PSIG, AIR'],
    ['_150H2O', '150H2O: 15.1-150 PSIG, WATER'],
    ['_150INERT', '150INERT: 15.1-150 PSIG, INERT GAS'],
    ['_150O2', '150O2: 15.1-150 PSIG, OXYGEN'],
    ['_15AIR', '15AIR: 0-15 PSIG, AIR'],
    ['_15H2O', '15H2O: 0-15 PSIG, WATER'],
    ['_15INERT', '15INERT: 0-15 PSIG, INERT GAS'],
    ['_15O2', '15O2: 0-15 PSIG, OXYGEN'],
    ['_15VAC', '15VAC: 0-15 PSID, VACUUM'],
    ['_28ELEC', '28ELEC: 10.1-28 VOLT, ELECTRICAL'],
    ['_3000AIR', '3000AIR: 150.1-3000 PSIG, AIR'],
    ['_3000H2O', '3000H2O: 150.1-3000 PSIG, WATER'],
    ['_3000INERT', '3000INERT: 150.1-3000 PSIG, INERT GAS'],
    ['_3000O2', '3000O2: 150.1-3000 PSIG, OXYGEN'],
    ['_70LIFT', '70LIFT: 0-70 LB LIFTING EQUIPMENT'],
    ['_70SHIP', '70SHIP: 0-70 LB SHIPPING FIXTURE'],
    ['ALL', 'ALL: ITEMS IN THIS HDW CLASS'],
    ['FOOD', 'FOOD: FOOD PREPARATION'],
    ['GENEQ', 'GENEQ: NON-PRESS,NON-ELEC,NON-CRIT'],
    ['HDWCR', 'HDWCR: HDW,SCREWS,NUTS, CRITICAL'],
    ['HDWNC', 'HDWNC: HDW,SCREWS,NUTS, NON-CRITICAL'],
    ['UNLIMAIR', 'UNLIMAIR: 3001 PSIG & ABOVE, AIR'],
    ['UNLIMELEC', 'UNLIMELEC: 120.1 VOLT & ABOVE, ELECTRICAL'],
    ['UNLIMH2O', 'UNLIMH2O: 3001 PSIG & ABOVE, WATER'],
    ['UNLIMINERT', 'UNLIMINERT: 3001 PSIG & ABOVE, INERT GAS'],
    ['UNLIMLIFT', 'UNLIMLIFT: 70.1 LB & UP LIFTING EQUIPMENT'],
    ['UNLIMO2', 'UNLIMO2: 3001 PSIG & ABOVE, OXYGEN'],
    ['UNLIMSHIP', 'UNLIMSHIP: 70.1 LB & UP SHIPPING FIXTURE']
]);
export const AssetClassCodeDisplay = new Map([
    ['"A"', '"A": ALL; FLIGHT OR FLIGHT EQUIVALENT HARDWARE'],
    ['"B"', '"B": RED RISK ASSESSMENT; NON-FLIGHT ASSET'],
    ['"C"', '"C": YELLOW RISK ASSESSMENT; NON-FLIGHT ASSET'],
    ['"D"', '"D": GREEN RISK ASSESSMENT; NON-FLIGHT ASSET'],
    ['"N"', '"N": ASSET CLASS NOT APPLICABLE']
]);
export var ItemClassesWithoutMultiple;
(function (ItemClassesWithoutMultiple) {
    ItemClassesWithoutMultiple["CLASS_I"] = "CLASS_I";
    ItemClassesWithoutMultiple["CLASS_IE"] = "CLASS_IE";
    ItemClassesWithoutMultiple["CLASS_II"] = "CLASS_II";
    ItemClassesWithoutMultiple["CLASS_III"] = "CLASS_III";
    ItemClassesWithoutMultiple["CLASS_IIIC"] = "CLASS_IIIC";
    ItemClassesWithoutMultiple["CLASS_IIIW"] = "CLASS_IIIW";
    ItemClassesWithoutMultiple["GROUND_SUPPORT_EQUIPMENT"] = "GROUND_SUPPORT_EQUIPMENT";
    ItemClassesWithoutMultiple["SPECIAL_TEST_EQUIPMENT"] = "SPECIAL_TEST_EQUIPMENT";
    ItemClassesWithoutMultiple["PUBLIC_AFFAIRS_ONLY"] = "PUBLIC_AFFAIRS_ONLY";
    ItemClassesWithoutMultiple["PROGRAM_SUPPORT_EQUIPMENT"] = "PROGRAM_SUPPORT_EQUIPMENT";
    ItemClassesWithoutMultiple["_PROTOTYPE"] = "_PROTOTYPE";
    ItemClassesWithoutMultiple["UC"] = "UC";
    ItemClassesWithoutMultiple["UNKNOWN"] = "UNKNOWN";
})(ItemClassesWithoutMultiple || (ItemClassesWithoutMultiple = {}));
export const ItemClassesWithoutMultipleDisplay = new Map([
    [ItemClassesWithoutMultiple.CLASS_I, 'I'],
    [ItemClassesWithoutMultiple.CLASS_II, 'II'],
    [ItemClassesWithoutMultiple.CLASS_III, 'III'],
    [ItemClassesWithoutMultiple.CLASS_IE, 'IE'],
    [ItemClassesWithoutMultiple.CLASS_IIIC, 'IIIC'],
    [ItemClassesWithoutMultiple.CLASS_IIIW, 'IIIW'],
    [ItemClassesWithoutMultiple.GROUND_SUPPORT_EQUIPMENT, 'GSE'],
    [ItemClassesWithoutMultiple.SPECIAL_TEST_EQUIPMENT, 'STE/"D"'],
    [ItemClassesWithoutMultiple.PUBLIC_AFFAIRS_ONLY, 'PAO'],
    [ItemClassesWithoutMultiple._PROTOTYPE, 'PRT'],
    [ItemClassesWithoutMultiple.PROGRAM_SUPPORT_EQUIPMENT, 'PSE'],
    [ItemClassesWithoutMultiple.UC, 'UC'],
    [ItemClassesWithoutMultiple.UNKNOWN, 'Unknown']
]);
export const ItemInstanceTypeToValidAssetClassCode = new Map([
    [ItemInstanceInventoryType.CLASS_I, ['A', 'N']],
    [ItemInstanceInventoryType.CLASS_II, ['A', 'N']],
    [ItemInstanceInventoryType.CLASS_III, ['B', 'C', 'D', 'N']],
    [ItemInstanceInventoryType.CLASS_IIIW, ['B', 'C', 'D', 'N']],
    [ItemInstanceInventoryType.GROUND_SUPPORT_EQUIPMENT, ['B', 'C', 'D', 'N']],
    [ItemInstanceInventoryType._PROTOTYPE, ['D', 'N']],
    [ItemInstanceInventoryType.PROGRAM_SUPPORT_EQUIPMENT, ['D', 'N']],
    [ItemInstanceInventoryType.PUBLIC_AFFAIRS_ONLY, ['D', 'N']],
    [ItemInstanceInventoryType.SPECIAL_TEST_EQUIPMENT, ['D', 'N']]
]);
export const AssetUsageCodeToValidAssetClassCode = new Map([
    [
        'UNLIMO2',
        [
            {
                classCode: 'B',
                isGseCritical: true
            }
        ]
    ],
    [
        '_3000O2',
        [
            {
                classCode: 'B',
                isGseCritical: true
            }
        ]
    ],
    [
        '_150O2',
        [
            {
                classCode: 'B',
                isGseCritical: true
            }
        ]
    ],
    [
        '_150O2',
        [
            {
                classCode: 'B',
                isGseCritical: true
            }
        ]
    ],
    [
        'UNLIMAIR',
        [
            {
                classCode: 'B',
                isGseCritical: true
            },
            {
                classCode: 'C',
                isGseCritical: false
            },
            {
                classCode: 'D',
                isGseCritical: false
            }
        ]
    ],
    [
        '_3000AIR',
        [
            {
                classCode: 'B',
                isGseCritical: true
            },
            {
                classCode: 'C',
                isGseCritical: false
            },
            {
                classCode: 'D',
                isGseCritical: false
            }
        ]
    ],
    [
        '_150AIR',
        [
            {
                classCode: 'B',
                isGseCritical: true
            },
            {
                classCode: 'C',
                isGseCritical: false
            },
            {
                classCode: 'D',
                isGseCritical: false
            }
        ]
    ],
    [
        '_15AIR',
        [
            {
                classCode: 'B',
                isGseCritical: true
            },
            {
                classCode: 'C',
                isGseCritical: false
            },
            {
                classCode: 'D',
                isGseCritical: false
            }
        ]
    ],
    [
        'UNLIMINERT',
        [
            {
                classCode: 'B',
                isGseCritical: true
            },
            {
                classCode: 'C',
                isGseCritical: false
            },
            {
                classCode: 'D',
                isGseCritical: false
            }
        ]
    ],
    [
        '_3000INERT',
        [
            {
                classCode: 'B',
                isGseCritical: true
            },
            {
                classCode: 'C',
                isGseCritical: false
            },
            {
                classCode: 'D',
                isGseCritical: false
            }
        ]
    ],
    [
        '_150INERT',
        [
            {
                classCode: 'B',
                isGseCritical: true
            },
            {
                classCode: 'C',
                isGseCritical: false
            },
            {
                classCode: 'D',
                isGseCritical: false
            }
        ]
    ],
    [
        '_15INERT',
        [
            {
                classCode: 'B',
                isGseCritical: true
            },
            {
                classCode: 'C',
                isGseCritical: false
            },
            {
                classCode: 'D',
                isGseCritical: false
            }
        ]
    ],
    [
        '_15O2',
        [
            {
                classCode: 'B',
                isGseCritical: true
            }
        ]
    ],
    [
        'UNLIMH2O',
        [
            {
                classCode: 'B',
                isGseCritical: true
            },
            {
                classCode: 'C',
                isGseCritical: false
            },
            {
                classCode: 'D',
                isGseCritical: false
            }
        ]
    ],
    [
        '_3000H2O',
        [
            {
                classCode: 'B',
                isGseCritical: true
            },
            {
                classCode: 'C',
                isGseCritical: false
            },
            {
                classCode: 'D',
                isGseCritical: false
            }
        ]
    ],
    [
        '_150H2O',
        [
            {
                classCode: 'B',
                isGseCritical: true
            },
            {
                classCode: 'C',
                isGseCritical: false
            },
            {
                classCode: 'D',
                isGseCritical: false
            }
        ]
    ],
    [
        '_15H2O',
        [
            {
                classCode: 'B',
                isGseCritical: true
            },
            {
                classCode: 'C',
                isGseCritical: false
            },
            {
                classCode: 'D',
                isGseCritical: false
            }
        ]
    ],
    [
        '_15VAC',
        [
            {
                classCode: 'B',
                isGseCritical: true
            },
            {
                classCode: 'C',
                isGseCritical: false
            },
            {
                classCode: 'D',
                isGseCritical: false
            }
        ]
    ],
    [
        'UNLIMELEC',
        [
            {
                classCode: 'B',
                isGseCritical: true
            },
            {
                classCode: 'C',
                isGseCritical: false
            },
            {
                classCode: 'D',
                isGseCritical: false
            }
        ]
    ],
    [
        '_120ELEC',
        [
            {
                classCode: 'B',
                isGseCritical: true
            },
            {
                classCode: 'C',
                isGseCritical: false
            },
            {
                classCode: 'D',
                isGseCritical: false
            }
        ]
    ],
    [
        '_28ELEC',
        [
            {
                classCode: 'B',
                isGseCritical: true
            },
            {
                classCode: 'C',
                isGseCritical: false
            },
            {
                classCode: 'D',
                isGseCritical: false
            }
        ]
    ],
    [
        '_10ELEC',
        [
            {
                classCode: 'B',
                isGseCritical: true
            },
            {
                classCode: 'C',
                isGseCritical: false
            },
            {
                classCode: 'D',
                isGseCritical: false
            }
        ]
    ],
    [
        'FOOD',
        [
            {
                classCode: 'C',
                isGseCritical: false
            },
            {
                classCode: 'D',
                isGseCritical: false
            }
        ]
    ],
    [
        'UNLIMLIFT',
        [
            {
                classCode: 'B',
                isGseCritical: true
            }
        ]
    ],
    [
        'UNLIMSHIP',
        [
            {
                classCode: 'C',
                isGseCritical: false
            },
            {
                classCode: 'D',
                isGseCritical: false
            }
        ]
    ],
    [
        '_70LIFT',
        [
            {
                classCode: 'B',
                isGseCritical: true
            },
            {
                classCode: 'C',
                isGseCritical: true
            }
        ]
    ],
    [
        '_70SHIP',
        [
            {
                classCode: 'C',
                isGseCritical: false
            },
            {
                classCode: 'D',
                isGseCritical: false
            }
        ]
    ],
    [
        'HDWCR',
        [
            {
                classCode: 'B',
                isGseCritical: true
            }
        ]
    ],
    [
        'HDWNC',
        [
            {
                classCode: 'D',
                isGseCritical: false
            }
        ]
    ],
    [
        'GENEQ',
        [
            {
                classCode: 'D',
                isGseCritical: false
            }
        ]
    ],
    [
        'ALL',
        [
            {
                classCode: 'B',
                isGseCritical: false
            },
            {
                classCode: 'A',
                isGseCritical: false
            },
            {
                classCode: 'C',
                isGseCritical: false
            },
            {
                classCode: 'D',
                isGseCritical: false
            },
            {
                classCode: 'N',
                isGseCritical: false
            }
        ]
    ]
]);
export const InventoryStatusDisplay = new Map([
    [InventoryStatus.IN_GRIEF, 'In Grief'],
    [InventoryStatus.IN_INVENTORY, 'In Inventory'],
    [InventoryStatus.IN_TRANSIT, 'In Transit'],
    [InventoryStatus.ISSUED, 'Issued'],
    [InventoryStatus.NEEDS_INSPECTION, 'Needs Inspection'],
    [InventoryStatus.NEEDS_TRANSFER, 'Needs Transfer'],
    [InventoryStatus.ON_DOCK, 'On Dock'],
    [InventoryStatus.REQUESTED, 'Requested']
]);
export const GroundSupportEquipmentTypeDisplay = new Map([
    [GroundSupportEquipmentType._2_KN_LOAD_CELL, '2 KN LOAD CELL'],
    [GroundSupportEquipmentType.AIR_FLOW_MONITOR, 'AIR FLOW MONITOR'],
    [GroundSupportEquipmentType.ALARM_PANEL, 'ALARM PANEL'],
    [GroundSupportEquipmentType.AMPLIFIER, 'AMPLIFIER'],
    [GroundSupportEquipmentType.ANALYZER, 'ANALYZER'],
    [GroundSupportEquipmentType.APFR_GSE_TETHER_ASSY, 'APFR GSE TETHER ASSY'],
    [GroundSupportEquipmentType.ATTENUATOR, 'ATTENUATOR'],
    [GroundSupportEquipmentType.BATTERY_CHARGER_DISCHARGER, 'BATTERY CHARGER DISCHARGER'],
    [GroundSupportEquipmentType.BATTERY_CHARGER_EMU_LIB, 'BATTERY CHARGER EMU LIB'],
    [GroundSupportEquipmentType.BATTERY_HITESTER, 'BATTERY HITESTER'],
    [GroundSupportEquipmentType.BLEED_FIXTURE, 'BLEED FIXTURE'],
    [GroundSupportEquipmentType.BREAKOUT_BOX, 'BREAKOUT BOX'],
    [GroundSupportEquipmentType.BUBBLE_FLOWMETER_KIT, 'BUBBLE FLOWMETER KIT'],
    [GroundSupportEquipmentType.CABLE, 'CABLE'],
    [GroundSupportEquipmentType.CALIBRATED_LEAK, 'CALIBRATED LEAK'],
    [GroundSupportEquipmentType.CALIPER, 'CALIPER'],
    [GroundSupportEquipmentType.CARRYING_FRAME, 'CARRYING FRAME'],
    [GroundSupportEquipmentType.CCC_SIMULATOR, 'CCC SIMULATOR'],
    [GroundSupportEquipmentType.CLEAN_ROOM_LSS, 'CLEAN ROOM LSS'],
    [GroundSupportEquipmentType.CLEAN_ROOM_SOP, 'CLEAN ROOM SOP'],
    [GroundSupportEquipmentType.CONTACT_TOOL, 'CONTACT TOOL'],
    [GroundSupportEquipmentType.COOLER, 'COOLER'],
    [GroundSupportEquipmentType.COUNTER, 'COUNTER'],
    [GroundSupportEquipmentType.CRIMP_TOOL, 'CRIMP TOOL'],
    [GroundSupportEquipmentType.CURRENT_METER, 'CURRENT METER'],
    [GroundSupportEquipmentType.DATA_ACQUISITION_UNIT, 'DATA ACQUISITION UNIT'],
    [GroundSupportEquipmentType.DECADE_RESISTOR, 'DECADE RESISTOR'],
    [GroundSupportEquipmentType.DESKTOP_COMPUTER, 'DESKTOP COMPUTER'],
    [GroundSupportEquipmentType.DIA_PUMP, 'DIA PUMP'],
    [GroundSupportEquipmentType.DIAL_CHAMFER_GAUGE, 'DIAL CHAMFER GAUGE'],
    [GroundSupportEquipmentType.DIGITAL_LEVEL, 'DIGITAL LEVEL'],
    [GroundSupportEquipmentType.DIT_MCO_2_CABLE_TEST_SYSTEM_ASSEMBLY, 'DIT MCO 2 CABLE TEST SYSTEM ASSEMBLY'],
    [GroundSupportEquipmentType.ELECTRICAL_GLOVES, 'ELECTRICAL GLOVES'],
    [GroundSupportEquipmentType.ELECTRONIC_LOAD, 'ELECTRONIC LOAD'],
    [GroundSupportEquipmentType.EMU_DISPLAY_STAND_ASSY, 'EMU DISPLAY STAND ASSY'],
    [GroundSupportEquipmentType.EMU_GROUND_TEST_FIXTURE_FRAME, 'EMU GROUND TEST FIXTURE FRAME'],
    [GroundSupportEquipmentType.EMU_GROUND_TEST_FIXTURE, 'EMU GROUND TEST FIXTURE'],
    [GroundSupportEquipmentType.EMU_LIFTING_FIXTURE, 'EMU LIFTING FIXTURE'],
    [GroundSupportEquipmentType.EMU_OFFSET_HOLDING_FIXTURE, 'EMU OFFSET HOLDING FIXTURE'],
    [GroundSupportEquipmentType.EQUIPMENT_TBD, 'EQUIPMENT TBD'],
    [GroundSupportEquipmentType.FACILITY_B_A_SYSTEM, 'FACILITY "B" "A" SYSTEM'],
    [GroundSupportEquipmentType.FACILITY_BA_SYSTEM, 'FACILITY BA SYSTEM'],
    [GroundSupportEquipmentType.FACILITY_DA_SYSTEM, 'FACILITY DA SYSTEM'],
    [GroundSupportEquipmentType.FACILITY_H20_SYSTEM, 'FACILITY H20 SYSTEM'],
    [GroundSupportEquipmentType.FACILITY_H2O_SYSTEM, 'FACILITY H2O SYSTEM'],
    [GroundSupportEquipmentType.FACILITY_N2_SYSTEM, 'FACILITY N2 SYSTEM'],
    [GroundSupportEquipmentType.FACILITY_O2_SYSTEM, 'FACILITY O2 SYSTEM'],
    [GroundSupportEquipmentType.FC_FLOW_CONTROLLER, 'FC FLOW CONTROLLER'],
    [GroundSupportEquipmentType.FCD_FLOW_CONTROL_DISPLAY, 'FCD FLOW CONTROL DISPLAY'],
    [GroundSupportEquipmentType.FILTER, 'FILTER'],
    [GroundSupportEquipmentType.FIXTURE, 'FIXTURE'],
    [GroundSupportEquipmentType.FLEXHOSE, 'FLEXHOSE'],
    [GroundSupportEquipmentType.FLOW_BENCH, 'FLOW BENCH'],
    [GroundSupportEquipmentType.FLOWMETER, 'FLOWMETER'],
    [GroundSupportEquipmentType.FORCE_GAUGE, 'FORCE GAUGE'],
    [GroundSupportEquipmentType.FORK_HOOK, 'FORK HOOK'],
    [GroundSupportEquipmentType.FORKLIFT, 'FORKLIFT'],
    [GroundSupportEquipmentType.FT_FLOW_TRANSDUCER, 'FT FLOW TRANSDUCER'],
    [GroundSupportEquipmentType.FTD_FLOW_TRANSDUCER_DISPLAY, 'FTD FLOW TRANSDUCER DISPLAY'],
    [GroundSupportEquipmentType.FUME_HOOD, 'FUME HOOD'],
    [GroundSupportEquipmentType.FUNCTION_GENERATOR, 'FUNCTION GENERATOR'],
    [GroundSupportEquipmentType.GAGE_ACTIVE_WIF_PROBE, 'GAGE ACTIVE WIF PROBE'],
    [GroundSupportEquipmentType.GHD_HOISTING_SLING, 'GHD HOISTING SLING'],
    [GroundSupportEquipmentType.GLOVE_MEASURE_DEVICE, 'GLOVE MEASURE DEVICE'],
    [GroundSupportEquipmentType.GLUE_BENCH, 'GLUE BENCH'],
    [GroundSupportEquipmentType.GO_GAGE, 'GO GAGE'],
    [GroundSupportEquipmentType.GO_NO_GO_GAUGE, 'GO NO GO GAUGE'],
    [GroundSupportEquipmentType.GROUND_HANDLING_DEVICE, 'GROUND HANDLING DEVICE'],
    [GroundSupportEquipmentType.HEIGHT_EXTENDER_ASSY, 'HEIGHT EXTENDER ASSY'],
    [GroundSupportEquipmentType.HELIUM_LEAK_PROBE, 'HELIUM LEAK PROBE'],
    [GroundSupportEquipmentType.HIGH_POT_TESTER, 'HIGH POT TESTER'],
    [GroundSupportEquipmentType.HIGH_RESISTANCE_METER, 'HIGH RESISTANCE METER'],
    [GroundSupportEquipmentType.HOIST, 'HOIST'],
    [GroundSupportEquipmentType.HOSE_ASSEMBLY, 'HOSE ASSEMBLY'],
    [GroundSupportEquipmentType.HOSE_SWAGING_CART, 'HOSE SWAGING CART'],
    [GroundSupportEquipmentType.HYGRO_THERMOMETER, 'HYGRO THERMOMETER'],
    [GroundSupportEquipmentType.IMPACT_O_GRAPH, 'IMPACT O GRAPH'],
    [GroundSupportEquipmentType.IONIZER, 'IONIZER'],
    [GroundSupportEquipmentType.IR_THERMOMETER, 'IR THERMOMETER'],
    [GroundSupportEquipmentType.LAB_TOP_COMPUTER, 'LAB TOP COMPUTER'],
    [GroundSupportEquipmentType.LAUNCH_ENCLOSURE_LIFTING_EYE, 'LAUNCH ENCLOSURE LIFTING EYE'],
    [GroundSupportEquipmentType.LIFTING_BEAM, 'LIFTING BEAM'],
    [GroundSupportEquipmentType.LIFTING_FIXTURE, 'LIFTING FIXTURE'],
    [GroundSupportEquipmentType.LIFTING_STRAP, 'LIFTING STRAP'],
    [GroundSupportEquipmentType.LTA_SUSPENSION_ALIGNMENT_FIXTURE, 'LTA SUSPENSION ALIGNMENT FIXTURE'],
    [GroundSupportEquipmentType.MAN_LIFT, 'MAN LIFT'],
    [GroundSupportEquipmentType.MEASURING_TAPE, 'MEASURING TAPE'],
    [GroundSupportEquipmentType.MERCURY_VAPOR_ANALYZER, 'MERCURY VAPOR ANALYZER'],
    [GroundSupportEquipmentType.MICROMETER, 'MICROMETER'],
    [GroundSupportEquipmentType.MODULATION_METER, 'MODULATION METER'],
    [GroundSupportEquipmentType.MOISTURE_MONITOR, 'MOISTURE MONITOR'],
    [GroundSupportEquipmentType.MONITOR_GROUND, 'MONITOR GROUND'],
    [GroundSupportEquipmentType.MOUNTING_FRAME, 'MOUNTING FRAME'],
    [GroundSupportEquipmentType.MULITPLE_WATER_CONNECTOR, 'MULTIPLE WATER CONNECTOR'],
    [GroundSupportEquipmentType.MULTIMETER, 'MULTIMETER'],
    [GroundSupportEquipmentType.MULTIPLE_WATER_CONNECTOR_, 'MULTIPLE WATER CONNECTOR '],
    [GroundSupportEquipmentType.MULTITESTER, 'MULTITESTER'],
    [GroundSupportEquipmentType.NECK_PLATE_INTERFACE_FIXTURE, 'NECK PLATE INTERFACE FIXTURE'],
    [GroundSupportEquipmentType.O2_MONITOR, 'O2 MONITOR'],
    [GroundSupportEquipmentType.OHMMETER, 'OHMMETER'],
    [GroundSupportEquipmentType.OSCILLATOR, 'OSCILLATOR'],
    [GroundSupportEquipmentType.OSCILLOSCOPE, 'OSCILLOSCOPE'],
    [GroundSupportEquipmentType.OVEN, 'OVEN'],
    [GroundSupportEquipmentType.PALLET_JACK, 'PALLET JACK'],
    [GroundSupportEquipmentType.PANEL_METER, 'PANEL METER'],
    [GroundSupportEquipmentType.PARTICLE_COUNTER, 'PARTICLE COUNTER'],
    [GroundSupportEquipmentType.PH_METER, 'PH METER'],
    [GroundSupportEquipmentType.PIN_RETENTION_TOOL, 'PIN RETENTION TOOL'],
    [GroundSupportEquipmentType.PLATE_MILLIOHM_REFERENCE, 'PLATE MILLIOHM REFERENCE'],
    [GroundSupportEquipmentType.PLSS_WEIGHT_BOX, 'PLSS WEIGHT BOX'],
    [GroundSupportEquipmentType.POWER_SUPPLY, 'POWER SUPPLY'],
    [GroundSupportEquipmentType.PRESSURE_GAUGE, 'PRESSURE GAUGE'],
    [GroundSupportEquipmentType.PRESSURE_REGULATOR, 'PRESSURE REGULATOR'],
    [GroundSupportEquipmentType.PRESSURE_SWITCH, 'PRESSURE SWITCH'],
    [GroundSupportEquipmentType.PROBE, 'PROBE'],
    [GroundSupportEquipmentType.PT_PRESSURE_TRANSDUCER, 'PT PRESSURE TRANSDUCER'],
    [GroundSupportEquipmentType.PTD_PRESSURE_TRANSDUCER_DISPLAY, 'PTD PRESSURE TRANSDUCER DISPLAY'],
    [GroundSupportEquipmentType.RADIO_TEST_SET, 'RADIO TEST SET'],
    [GroundSupportEquipmentType.RECEIVER, 'RECEIVER'],
    [GroundSupportEquipmentType.RECORDERS, 'RECORDERS'],
    [GroundSupportEquipmentType.REGULATOR, 'REGULATOR'],
    [GroundSupportEquipmentType.RELIEF_VALVE, 'RELIEF VALVE'],
    [GroundSupportEquipmentType.SAMPLE_CYLINDER, 'SAMPLE CYLINDER'],
    [GroundSupportEquipmentType.SCALE, 'SCALE'],
    [GroundSupportEquipmentType.SEMU_LIFTING_BAR_ASSY, 'SEMU LIFTING BAR ASSY'],
    [GroundSupportEquipmentType.SENSOR_MODULE, 'SENSOR MODULE'],
    [GroundSupportEquipmentType.SHACKLE, 'SHACKLE'],
    [GroundSupportEquipmentType.SHIPPING_CONTAINER, 'SHIPPING CONTAINER'],
    [GroundSupportEquipmentType.SHUCK_TEST_MICROMETER_GAUGE_ASSY, 'SHUCK TEST MICROMETER GAUGE ASSY'],
    [GroundSupportEquipmentType.SOLDERING_STATION, 'SOLDERING STATION'],
    [GroundSupportEquipmentType.SOUND_LEVEL_CALIBRATOR, 'SOUND LEVEL CALIBRATOR'],
    [GroundSupportEquipmentType.SOUND_LEVEL_METER, 'SOUND LEVEL METER'],
    [GroundSupportEquipmentType.SOUND_PRESSURE_METER, 'SOUND PRESSURE METER'],
    [GroundSupportEquipmentType.STATIC_LOCATOR, 'STATIC LOCATOR'],
    [GroundSupportEquipmentType.SUPPORT_PLATFORM_EMU_ADJUSTIBLE_HEIGHT, 'SUPPORT PLATFORM EMU ADJUSTIBLE HEIGHT'],
    [GroundSupportEquipmentType.SURFACE_RESIST_IND, 'SURFACE RESIST IND'],
    [GroundSupportEquipmentType.SURFACE_RESISTIVITY_METER, 'SURFACE RESISTIVITY METER'],
    [GroundSupportEquipmentType.TD_TEMPERATURE_DISPLAY, 'TD TEMPERATURE DISPLAY'],
    [GroundSupportEquipmentType.TEMP_HUMIDITY_RECORDER, 'TEMP HUMIDITY RECORDER'],
    [GroundSupportEquipmentType.TEMPERATURE_CONTROLLER, 'TEMPERATURE CONTROLLER'],
    [GroundSupportEquipmentType.TEMPERATURE_LIMIT_CONTROLLER, 'TEMPERATURE LIMIT CONTROLLER'],
    [GroundSupportEquipmentType.TEMPERATURE_RECORDER, 'TEMPERATURE RECORDER'],
    [GroundSupportEquipmentType.TEST_STAND, 'TEST STAND'],
    [GroundSupportEquipmentType.THERMOCOUPLE_DISPLAY, 'THERMOCOUPLE DISPLAY'],
    [GroundSupportEquipmentType.THERMOMETER, 'THERMOMETER'],
    [GroundSupportEquipmentType.TIMER_CLOCK, 'TIMER CLOCK'],
    [GroundSupportEquipmentType.TIMER, 'TIMER'],
    [GroundSupportEquipmentType.TORQUE_DRIVER, 'TORQUE DRIVER'],
    [GroundSupportEquipmentType.TORQUE_METER, 'TORQUE METER'],
    [GroundSupportEquipmentType.TORQUE_TESTER, 'TORQUE TESTER'],
    [GroundSupportEquipmentType.TORQUE_TRANSDUCER, 'TORQUE TRANSDUCER'],
    [GroundSupportEquipmentType.TORQUE_WRENCH, 'TORQUE WRENCH'],
    [GroundSupportEquipmentType.TT_TEMPERATURE_TRANSMITTER, 'TT TEMPERATURE TRANSMITTER'],
    [GroundSupportEquipmentType.VACUUM_CHAMBER_ASSY_22_LITER, 'VACUUM CHAMBER ASSY 22 LITER'],
    [GroundSupportEquipmentType.VACUUM_DISPLAY, 'VACUUM DISPLAY'],
    [GroundSupportEquipmentType.VACUUM_OVEN, 'VACUUM OVEN'],
    [GroundSupportEquipmentType.VACUUM_PUMP, 'VACUUM PUMP'],
    [GroundSupportEquipmentType.VACUUM_SENSOR, 'VACUUM SENSOR'],
    [GroundSupportEquipmentType.VEHICLE_BOBTAIL_TRUCK, 'VEHICLE BOBTAIL TRUCK'],
    [GroundSupportEquipmentType.VELOCITY_METER, 'VELOCITY METER'],
    [GroundSupportEquipmentType.VENT_HOOD, 'VENT HOOD'],
    [GroundSupportEquipmentType.VITAL_SIGNS_SIMILATOR, 'VITAL SIGNS SIMILATOR'],
    [GroundSupportEquipmentType.VOLTMETER, 'VOLTMETER'],
    [GroundSupportEquipmentType.VRMS_METER, 'VRMS METER'],
    [GroundSupportEquipmentType.WATER_CONNECTOR_AIRLOCK, 'WATER CONNECTOR AIRLOCK'],
    [GroundSupportEquipmentType.WATER_TANK, 'WATER TANK'],
    [GroundSupportEquipmentType.WATER_TRAP, 'WATER TRAP'],
    [GroundSupportEquipmentType.WAVEFORM_GENERATOR, 'WAVEFORM GENERATOR'],
    [GroundSupportEquipmentType.WAVEFORM_MONITOR, 'WAVEFORM MONITOR'],
    [GroundSupportEquipmentType.WEIGHT_BOX, 'WEIGHT BOX'],
    [GroundSupportEquipmentType.WELD_EXHAUST_HOOD, 'WELD EXHAUST HOOD'],
    [GroundSupportEquipmentType.WIRE_CRIMPER, 'WIRE CRIMPER'],
    [GroundSupportEquipmentType.WIRE_STRIPPER, 'WIRE STRIPPER'],
    [GroundSupportEquipmentType.WRIST_STRAP_FOOTWEAR_CHECKER, 'WRIST STRAP FOOTWEAR CHECKER'],
    [GroundSupportEquipmentType.X_RAY_MACHINE, 'X RAY MACHINE']
]);
export var HardwareListNote;
(function (HardwareListNote) {
    HardwareListNote["ONE"] = "1";
    HardwareListNote["TWO"] = "2";
    HardwareListNote["THREE"] = "3";
    HardwareListNote["FOUR"] = "4";
    HardwareListNote["FIVE"] = "5";
    HardwareListNote["SIX"] = "6";
    HardwareListNote["SEVEN"] = "7";
    HardwareListNote["EIGHT"] = "8";
    HardwareListNote["NINE"] = "9";
    HardwareListNote["TEN"] = "10";
    HardwareListNote["ELEVEN"] = "11";
    HardwareListNote["TWELVE"] = "12";
    HardwareListNote["THIRTEEN"] = "13";
    HardwareListNote["FOURTEEN"] = "14";
    HardwareListNote["FIFTEEN"] = "15";
    HardwareListNote["SIXTEEN"] = "16";
    HardwareListNote["SEVENTEEN"] = "17";
    HardwareListNote["EIGHTEEN"] = "18";
    HardwareListNote["NINETEEN"] = "19";
    HardwareListNote["TWENTY"] = "20";
})(HardwareListNote || (HardwareListNote = {}));
export const HardwareListNoteDisplay = new Map([
    [HardwareListNote.ONE, '1. Not Required for Fit checks or Dry Runs.'],
    [HardwareListNote.TWO, '2. To be supplied by Test Director.'],
    [HardwareListNote.THREE, '3. TRR sheets not required.'],
    [
        HardwareListNote.FOUR,
        '4. All TRR information contained in "OBS" sheet. TRR sheet obtained upon initial assignment by Life Sciences Project Division. TRR sheets to have "IR-OBS" in block 17.'
    ],
    [
        HardwareListNote.FIVE,
        '5. TRR sheets obtained upon initial receipt in CTSD Bont. TRR sheets to have "IR" in block 17.'
    ],
    [HardwareListNote.SIX, '6. TRR sheet for the SCU/ESCU provided once per test series.'],
    [HardwareListNote.SEVEN, '7. TRR information contained in "PLSS" TRR sheet.'],
    [HardwareListNote.EIGHT, '8. Quality to verify P/"N" & S/"N" at applicable chamber.'],
    [HardwareListNote.NINE, '9. TRR information contained in "HUT" TRR sheets.'],
    [HardwareListNote.TEN, '10. TRR information contained in "Helmet/EVA Assembly" TRR sheets.'],
    [HardwareListNote.ELEVEN, '11. TRR information contained in "LTA" TRR sheets.'],
    [HardwareListNote.TWELVE, '12. TRR information contained in "CAA" TRR sheets.'],
    [HardwareListNote.THIRTEEN, '13. TRR information contained in "LCVG" TRR sheets.'],
    [HardwareListNote.FOURTEEN, '14. TRR information contained in "Glove" TRR sheets.'],
    [HardwareListNote.FIFTEEN, '15. TRR information contained in "Arm Assy." TRR sheets.'],
    [HardwareListNote.SIXTEEN, '16. TRR sheets for this hardware provided once per this test series.'],
    [HardwareListNote.SEVENTEEN, '17. TRR sheets to be provided by Wyle Labs.'],
    [HardwareListNote.EIGHTEEN, '18. Non-serialized items available for use.'],
    [HardwareListNote.NINETEEN, '19. The Metox canister is Prime for the Crew Member.'],
    [
        HardwareListNote.TWENTY,
        '20. This part is not in the EMUHTS(owner of this Hardware List). The information is from the requesting engineer.'
    ]
]);
export const HardwareListTypeDisplay = new Map([
    [HardwareListType.CREWMEMBER, 'Crewmember'],
    [HardwareListType.DELIVERY_MATRIX, 'Delivery Matrix'],
    [HardwareListType.EMU_HARDWARE, 'EMU'],
    [HardwareListType.FLIGHT_SUPPORT_HARDWARE, 'Flight Support'],
    [HardwareListType.GROUND_SUPPORT_EQUIPMENT, 'GSE'],
    [HardwareListType.HARDWARE, 'Hardware'],
    [HardwareListType.TOOLS, 'Tools'],
    [HardwareListType.WORK_ORDER_STEP_HARDWARE, 'Hardware for step'],
    [HardwareListType.WORK_ORDER_STEP_TOOLS, 'Tools for step'],
    [HardwareListType.COSMIC_DOCUMENT_HARDWARE, 'Hardware for Doc'],
    [HardwareListType.COSMIC_DOCUMENT_TOOLS, 'Tools for Doc']
]);
export const CalibrationTypeDisplay = new Map([
    [CalibrationType.ANNUAL_CERTIFICATION, 'Annual certification'],
    [CalibrationType.ANNUAL_LEAKAGE, 'Annual leakage'],
    [CalibrationType.ANNUAL_SAMPLING, 'Annual sampling'],
    [CalibrationType.CALIBRATION, 'Calibration'],
    [CalibrationType.CLASS_I_INSPECTION, 'Class I inspection'],
    [CalibrationType.CLASS_II_INSPECTION, 'Class II inspection'],
    [CalibrationType.CLASS_III_INSPECTION, 'Class III inspection'],
    [CalibrationType.DOT_CERTIFICATION, 'DOT certification'],
    [CalibrationType.FILTER_CHANGE_OUT, 'Filter change out'],
    [CalibrationType.FUNCTIONAL_CHECK_OUT, 'Functional check out'],
    [CalibrationType.GENERAL, 'General'],
    [CalibrationType.H2O_IODINE_TREATMENT, 'H<sub>2</sub> iodine treatment'],
    [CalibrationType.HYDROSTATIC_TEST, 'Hydrostatic test'],
    [CalibrationType.IN_HOUSE_CALIBRATION, 'In house calibration'],
    [CalibrationType.LOAD_CERTIFICATION, 'Load certification'],
    [CalibrationType.MONTHLY_SAMPLING, 'Monthly sampling'],
    [CalibrationType.PNEUMATIC_TEST, 'Pneumatic test'],
    [CalibrationType.PREVENTATIVE_MAINTENANCE, 'Preventative maintenance'],
    [CalibrationType.PROOF_PRESSURE_TEST, 'Proof pressure test'],
    [CalibrationType.QUARTERLY_CERTIFICATION, 'Quarterly certification'],
    [CalibrationType.RADIATION_SAFETY_CERTIFICATE, 'Radiation safety certificate'],
    [CalibrationType.RELIEF_VALVE_CERTIFICATION, 'Relief valve certification']
]);
export var LimitedLifeCycleField;
(function (LimitedLifeCycleField) {
    LimitedLifeCycleField[LimitedLifeCycleField["FLIGHT"] = LimitedLifeType.FLIGHT] = "FLIGHT";
    LimitedLifeCycleField[LimitedLifeCycleField["CYCLE"] = LimitedLifeType.CYCLE] = "CYCLE";
    LimitedLifeCycleField[LimitedLifeCycleField["EVA"] = LimitedLifeType.EVA] = "EVA";
})(LimitedLifeCycleField || (LimitedLifeCycleField = {}));
export var LimitedLifeIntervalField;
(function (LimitedLifeIntervalField) {
    LimitedLifeIntervalField[LimitedLifeIntervalField["SHELF"] = LimitedLifeType.SHELF] = "SHELF";
    LimitedLifeIntervalField[LimitedLifeIntervalField["USAGE"] = LimitedLifeType.USAGE] = "USAGE";
    LimitedLifeIntervalField[LimitedLifeIntervalField["OPERATION"] = LimitedLifeType.OPERATION] = "OPERATION";
    LimitedLifeIntervalField[LimitedLifeIntervalField["PRESSURIZED_TIME"] = LimitedLifeType.PRESSURIZED_TIME] = "PRESSURIZED_TIME";
    LimitedLifeIntervalField[LimitedLifeIntervalField["INTERVAL"] = LimitedLifeType.INTERVAL] = "INTERVAL";
    LimitedLifeIntervalField[LimitedLifeIntervalField["ON_ORBIT"] = LimitedLifeType.ON_ORBIT] = "ON_ORBIT";
    LimitedLifeIntervalField[LimitedLifeIntervalField["STATIC"] = LimitedLifeType.STATIC] = "STATIC";
})(LimitedLifeIntervalField || (LimitedLifeIntervalField = {}));
// CYCLE ONLY TYPES
export const LimitedLifeCycleFieldDisplay = new Map([
    [LimitedLifeCycleField.FLIGHT, 'Flight Life'],
    [LimitedLifeCycleField.CYCLE, 'Cycle / Count Life'],
    [LimitedLifeCycleField.EVA, 'EVA Life']
]);
// INTERVAL ONLY TYPES
export const LimitedLifeIntervalFieldDisplay = new Map([
    [LimitedLifeIntervalField.SHELF, 'Shelf Life'],
    [LimitedLifeIntervalField.USAGE, 'Usage Life'],
    [LimitedLifeIntervalField.OPERATION, 'Operational Life'],
    [LimitedLifeIntervalField.PRESSURIZED_TIME, 'Pressurized Time Life'],
    [LimitedLifeIntervalField.INTERVAL, 'Interval Life'],
    [LimitedLifeIntervalField.ON_ORBIT, 'On Orbit Life'],
    [LimitedLifeIntervalField.STATIC, 'Static Life']
]);
// ALL LIMITED LIFE TYPES
export const LimitedLifeTypeDisplay = new Map([
    [LimitedLifeType.SHELF, 'Shelf Life'],
    [LimitedLifeType.USAGE, 'Usage Life'],
    [LimitedLifeType.OPERATION, 'Operational Life'],
    [LimitedLifeType.PRESSURIZED_TIME, 'Pressurized Time Life'],
    [LimitedLifeType.INTERVAL, 'Interval Life'],
    [LimitedLifeType.ON_ORBIT, 'On Orbit Life'],
    [LimitedLifeType.STATIC, 'Static Life'],
    [LimitedLifeType.FLIGHT, 'Flight Life'],
    [LimitedLifeType.CYCLE, 'Cycle Life'],
    [LimitedLifeType.EVA, 'EVA Life']
]);
export const LimitedLifeTypeToPropertyName = new Map([
    [LimitedLifeType.SHELF, 'shelf'],
    [LimitedLifeType.USAGE, 'usage'],
    [LimitedLifeType.OPERATION, 'operational'],
    [LimitedLifeType.PRESSURIZED_TIME, 'pressurizedTime'],
    [LimitedLifeType.INTERVAL, 'interval'],
    [LimitedLifeType.ON_ORBIT, 'onOrbit'],
    [LimitedLifeType.STATIC, 'static'],
    [LimitedLifeType.FLIGHT, 'flight'],
    [LimitedLifeType.CYCLE, 'cycle'],
    [LimitedLifeType.EVA, 'eva']
]);
// Physical Inventory Audit
export var AuditItemVisibilityToggle;
(function (AuditItemVisibilityToggle) {
    AuditItemVisibilityToggle["ONLY_OPEN"] = "ONLY_OPEN";
    AuditItemVisibilityToggle["ONLY_CLOSED"] = "ONLY_CLOSED";
    AuditItemVisibilityToggle["ONLY_DEVIATIONS"] = "ONLY_DEVIATIONS";
})(AuditItemVisibilityToggle || (AuditItemVisibilityToggle = {}));
