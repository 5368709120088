var _a;
import { __decorate, __metadata } from "tslib";
import gql from 'graphql-tag';
import { Component, Watch } from 'vue-property-decorator';
import { Maybe } from '~/db_types';
import { Side } from '~/db_types/swagger_types';
import BaseMini from '~/nasa_ui/base/BaseMini';
import { DEFAULT_DASH } from '~/nasa_ui/constants';
import { GetSlimItemMasterByDrawingNumberAsBuiltSide, GetSlimItemMasterByNodeId } from '~/nasa_ui/DAL/itemMaster';
import Hardware from '~/nasa_ui/modules/Hardware';
import { EntityType } from '~/nasa_ui/types';
import { EquipmentGroupNumberOnlyDisplay } from '~/nasa_ui/types/enums/equipment-group';
import { FederalSupplyClassFCSRef } from '~/nasa_ui/types/enums/federal-supply-class';
import { FederalSupplyClassToObjectClass } from '~/nasa_ui/types/enums/object-class';
let MiniItemMaster = class MiniItemMaster extends BaseMini {
    entityType = EntityType.ITEM_MASTER;
    localEntity = null;
    showItemInstances = false;
    showAhd = false;
    hardware = null;
    BASE_FETCH = this.nodeId
        ? gql `
        ${GetSlimItemMasterByNodeId}
      `
        : gql `
        ${GetSlimItemMasterByDrawingNumberAsBuiltSide}
      `;
    get equipmentGroupDisplay() {
        if (!this.localEntity || !this.localEntity.subType) {
            return DEFAULT_DASH;
        }
        return EquipmentGroupNumberOnlyDisplay.has(this.localEntity.subType)
            ? EquipmentGroupNumberOnlyDisplay.get(this.localEntity.subType)
            : DEFAULT_DASH;
    }
    get initializationStatusIconDisplay() {
        if (!this.localEntity || !this.hardware) {
            return null;
        }
        return this.hardware.initializationStatusIconDisplay.replace('approved', '').replace('rejected', '');
    }
    get isArchived() {
        return this.localEntity?.isArchived;
    }
    get itemNumberDisplay() {
        if (!this.localEntity) {
            return DEFAULT_DASH;
        }
        return this.$itemNumberDisplay(this.localEntity);
    }
    get manageLink() {
        const drawingNumber = this.localEntity?.drawingNumber;
        const asBuiltNumber = this.localEntity?.asBuiltNumber;
        const side = this.localEntity?.side;
        if (!drawingNumber || !asBuiltNumber || !side) {
            return '';
        }
        return `/hardware/${EntityType.ITEM_MASTER}/manage/${encodeURIComponent(drawingNumber)}/${encodeURIComponent(asBuiltNumber)}/${side}#information`;
    }
    get name() {
        if (!this.localEntity && !this.isFetching) {
            return 'Not found';
        }
        return this.itemNumberDisplay || DEFAULT_DASH;
    }
    get shouldShowSubText() {
        return Boolean(this.localEntity?.itemDrawingDescription);
    }
    get subNameText() {
        return this.hardware ? this.hardware.descriptionDisplay : DEFAULT_DASH;
    }
    get verticalLabelDisplayItems() {
        if (!this.localEntity || !this.hardware) {
            return [];
        }
        const items = [];
        const itemMaster = this.localEntity;
        // items.push({
        //   name: 'AsBuilt',
        //   value: this.$asBuiltNumberDisplay(this.localEntity?.asBuiltNumber)
        // });
        if (this.localEntity?.side !== Side.NONE) {
            items.push({
                name: 'Side',
                value: this.$sideDisplay(this.localEntity?.side)
            });
        }
        items.push({
            name: 'FSC',
            value: itemMaster.federalSupplyClass ? FederalSupplyClassFCSRef.get(itemMaster.federalSupplyClass) : DEFAULT_DASH
        });
        items.push({
            name: 'Obj cls',
            value: itemMaster.federalSupplyClass
                ? FederalSupplyClassToObjectClass.get(itemMaster.federalSupplyClass)
                : DEFAULT_DASH
        });
        return items;
    }
    onFetchUpdate(data) {
        if (data.itemMaster || data.itemMasterByDrawingNumberAndAsBuiltNumberAndSide) {
            this.$set(this, 'localEntity', data.itemMaster || data.itemMasterByDrawingNumberAndAsBuiltNumberAndSide);
            this.hardware = new Hardware(this.localEntity);
        }
    }
    onDrawingNumberForMiniChange(drawingNumber) {
        if (!drawingNumber) {
            return;
        }
        this.executeQuery();
    }
};
__decorate([
    Watch('drawingNumberForMini', { immediate: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_a = typeof Maybe !== "undefined" && Maybe) === "function" ? _a : Object]),
    __metadata("design:returntype", void 0)
], MiniItemMaster.prototype, "onDrawingNumberForMiniChange", null);
MiniItemMaster = __decorate([
    Component
], MiniItemMaster);
export default MiniItemMaster;
