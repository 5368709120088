import { __decorate, __metadata } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import BaseButton from '~/nasa_ui/base/BaseButton';
import { isValidColorValue, parseColorClasses } from '~/nasa_ui/utils/helpers/colors';
let ButtonGeneric = class ButtonGeneric extends BaseButton {
    loadingText;
    get computedColor() {
        if (!this.color) {
            return '';
        }
        if (isValidColorValue(this.color)) {
            return this.color;
        }
        return parseColorClasses(this.color);
    }
    get computedTextColor() {
        if (!this.textColor) {
            return '';
        }
        if (this.disabled) {
            return '#555';
        }
        if (isValidColorValue(this.textColor)) {
            return this.textColor;
        }
        return parseColorClasses(this.textColor);
    }
};
__decorate([
    Prop({
        default: 'Loading…',
        type: String
    }),
    __metadata("design:type", String)
], ButtonGeneric.prototype, "loadingText", void 0);
ButtonGeneric = __decorate([
    Component
], ButtonGeneric);
export default ButtonGeneric;
